import { Component } from '@angular/core';
import { DisciplineCardComponent } from '../discipline-card/discipline-card.component';
import { Discipline } from '../../models/discipline';
import { InfoServiceService } from '../../services/info-service.service';
import { FormsModule } from '@angular/forms';
import { StorageMap } from '@ngx-pwa/local-storage';

@Component({
  selector: 'app-discipline-page',
  standalone: true,
  imports: [DisciplineCardComponent, FormsModule],
  templateUrl: './discipline-page.component.html',
  styleUrl: './discipline-page.component.scss'
})
export class DisciplinePageComponent {

  disciplines: Discipline[] = [];
  filteredDisciplines: Discipline[] = [];
  filter: string = '';

  constructor(private infoService:InfoServiceService, private storage: StorageMap) { }

  ngOnInit() {
    this.infoService.getDisciplines().subscribe(disciplines => {
      this.disciplines = disciplines;
      this.filteredDisciplines = disciplines;
      this.storage.set('disciplines', JSON.stringify(disciplines)).subscribe(() => {});
      // localStorage.setItem('disciplines', JSON.stringify(disciplines));
    }, error => {

      this.disciplines = JSON.parse(localStorage.getItem('disciplines')||"[]");
      this.storage.get('disciplines',  { type: 'string' }).subscribe((d) => {
        this.disciplines = JSON.parse(d || "[]");
        this.filteredDisciplines = this.disciplines;
      });

    });
  }

  //function to filter disciplines by name case insensitive
  filterDisciplines() {
    this.filteredDisciplines = this.disciplines.filter(discipline => discipline.name.toLowerCase().includes(this.filter.toLowerCase()));
  }

}

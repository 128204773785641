import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-homepage',
  standalone: true,
  imports: [],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss'
})
export class HomepageComponent {

  currentUser: User | null = null;

  constructor(private auth: AuthService) {}

  ngOnInit() {
    this.auth.getUserInfo().subscribe({
      next: info => {
        this.currentUser = info;
      }
    })
  }

}

import { Injectable } from '@angular/core';
import { Discipline } from '../models/discipline';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InfoServiceService {

  constructor(private httpClient: HttpClient) { }

  public getDisciplines(): Observable<Discipline[]> {

    return this.httpClient.get<Discipline[]>(environment.baseUrl + '/info/disciplines');

  }
}

import { Component, Input, ViewEncapsulation, inject, input } from '@angular/core';
import { Discipline, DisciplineLevel } from '../../models/discipline';
import { AttributeComponent } from "../attribute/attribute.component";
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-discipline-card',
  standalone: true,
  imports: [AttributeComponent, NgbAccordionModule],
  templateUrl: './discipline-card.component.html',
  styleUrl: './discipline-card.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class DisciplineCardComponent {
  @Input() discipline: Discipline = new Discipline(0, '', '', [], []);
  selectedLevel: DisciplineLevel = new DisciplineLevel(0, 0, '', '', '', false);
  selectedSubDiscipline: Discipline = new Discipline(0, '', '', [], []);
	private modalService = inject(NgbModal);

  getAllDisciplines() : Discipline[] {
    let discList: Discipline[] = [];
    if (this.discipline.levels.length > 0) {
      discList.push(this.discipline);
    }
    //put all children disciplines in the list if they have levels
    for (let i = 0; i < this.discipline.children.length; i++) {
      let child = this.discipline.children[i];
      if (child.levels.length > 0) {
        discList.push(child);
      }
    }

    return discList;;
  }

  visualizzaLivello(longContent: any, livello: DisciplineLevel, discipline: Discipline) {
    this.selectedLevel = livello;
    this.selectedSubDiscipline = discipline;
    this.modalService.open(longContent,{ scrollable: true, size: 'lg' });
  }

  romanize(num: number): String {
    let digits: String[] = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+(digits.pop() || "") + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Campaign } from '../models/master';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {

  constructor(private http: HttpClient) { }

  assegnaExp(pgIdList: number[], exp: number) {
    return this.http.post<String>(environment.baseUrl + "/master/character/exp", {
      pgIds: pgIdList,
      exp: exp
    })
  }

  spendiExp(pgIdList: number[], exp: number) {
    return this.http.post<String>(environment.baseUrl + "/info/character/exp", {
      pgIds: pgIdList,
      exp: exp
    })
  }

  getCampagne() {
    return this.http.get<Campaign[]>(environment.baseUrl + "/info/mycampaigns");
  }

  getCampagna(campagnaId: string) {
    return this.http.get<Campaign>(environment.baseUrl + "/master/campaign/" + campagnaId)
  }

}

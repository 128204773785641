
<h1 class="text-center">Discipline</h1>

<!-- filter input field that call filterDisciplines -->
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <input type="text" class="form-control searchFilter" placeholder="Nome disciplina" [(ngModel)]="filter" (ngModelChange)="filterDisciplines()">
    </div>
  </div>


<div class="container">
  <div class="row">
    @for(discipline of filteredDisciplines; track $index) {
      <div class="col-md-4">
        <app-discipline-card [discipline]="discipline"></app-discipline-card>
      </div>
    }
  </div>
</div>

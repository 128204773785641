import { Component, Input } from '@angular/core';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-attribute',
  standalone: true,
  imports: [NgFor],
  templateUrl: './attribute.component.html',
  styleUrl: './attribute.component.scss'
})
export class AttributeComponent {

  @Input() maxPoints: number = 9;
  @Input() points: number = 0;
  @Input() spaced: boolean = false;

  dots: boolean[] = Array(this.maxPoints).fill(false);


  ngOnInit() {
    this.dots = Array(this.maxPoints).fill(false);
    if(this.points == 0) {
      return;
    }
    this.toggleDot(this.points)
  }

  toggleDot(index: number) {
    if (this.dots[index] && (index == this.dots.length-1 || !this.dots[index + 1] )) {
      this.dots[index] = false
      return;
    }
    for(let i = 0; i <= index; i++) {
      this.dots[i] = true;
    }
    for(let i = this.dots.length-1; i > index; i--) {
      this.dots[i] = false;
    }

    this.countPoints();
  }

  countPoints() {
    this.points = this.dots.filter(dot => dot).length;
  }
}

@if(characterSheet && character) {
  <!-- Attributi -->
  <div class="container">
    <div class="sheet-title"><h1>ATTRIBUTES</h1></div>
    <div class="row">
      @for (attrSet of attributesAreas; track $index) {
        <div class="col-md-4">
          <div class="sheet-title">
            <h2>{{attrSet}}</h2>
          </div>
          <table class="attribute-table">
            @for (attr of getObj(characterSheet.Attributes, attrSet); track $index) {
              <tr>
                <td #attribute_name [style]="attributeName">{{attr.description.replaceAll("_"," ")}}</td>
                <td>
                  <span>
                    @if (attr.specialization && attr.specialization != "") {
                      <i [title]="attr.specialization" (click)="openModal(content,attr.specialization)" class="bi bi-info-square-fill"></i>
                    }
                    @else {
                      <i class="bi bi-info-square"></i>
                    }
                  </span>
              </td>
                <td><span><app-attribute [points]="attr.value"></app-attribute></span></td>
              </tr>
            }
          </table>
        </div>
      }
    </div>
  </div>

  <!-- Abilita -->
  <div class="container">
    <div class="sheet-title"><h1>ABILITIES</h1></div>
    <div class="row">
      @for (attrSet of abilitiesAreas; track $index) {
        <div class="col-md-4">
          <div class="sheet-title">
            <h2>{{attrSet}}</h2>
          </div>
          <table class="attribute-table">
            @for (attr of getObj(characterSheet.Abilities, attrSet); track $index) {
              <tr>
                <td #attribute_name [style]="attributeName">{{attr.description.replaceAll("_"," ")}}</td>
                <td><span><i class="bi bi-info-square"></i></span></td>
                <td><span><app-attribute [points]="attr.value"></app-attribute></span></td>
              </tr>
            }
          </table>
        </div>
      }
    </div>
  </div>

  <!-- Vantaggi -->
  <div class="container">
    <div class="sheet-title"><h1>ADVANTAGES</h1></div>
    <div class="row">

        <!-- Discipline -->
        <div class="col-md-4">
          <div class="sheet-title">
            <h2>Disciplines</h2>
          </div>
          <table class="attribute-table">
            @for (dis of attrPadding(characterSheet.Advantages.Disciplines); track $index) {
              <tr>
                <td #attribute_name [style]="attributeName">{{dis.name}}</td>
                <td><span><i class="bi bi-info-square"></i></span></td>
                <td><span><app-attribute [points]="dis.value"></app-attribute></span></td>
              </tr>
            }
          </table>
        </div>
        <!-- Background -->
        <div class="col-md-4">
          <div class="sheet-title">
            <h2>Backgrounds</h2>
          </div>
          <table class="attribute-table">
            @for (dis of attrPadding(characterSheet.Advantages.Backgrounds); track $index) {
              <tr>
                <td #attribute_name [style]="attributeName">{{dis.name}}</td>
                <td><span><i class="bi bi-info-square"></i></span></td>
                <td><span><app-attribute [points]="dis.value"></app-attribute></span></td>
              </tr>
            }
          </table>
        </div>
        <!-- Background -->
        <div class="col-md-4">
          <div class="sheet-title">
            <h2>Virtues</h2>
          </div>
          <table class="attribute-table">
            @for (vir of getKeys(characterSheet.Advantages.Virtues); track $index) {
              <tr>
                <td #attribute_name [style]="attributeName">{{vir.replaceAll("_","/")}}</td>
                <td><span><i class="bi bi-info-square"></i></span></td>
                <td><span><app-attribute [points]="getObj(characterSheet.Advantages.Virtues, vir)"></app-attribute></span></td>
              </tr>
            }
          </table>
        </div>
    </div>
  </div>


  <!--  -->
  <div class="container">
    <div class="sheet-title"><h1>ABILITIES</h1></div>
    <div class="row">
      <!-- Altri tratti -->
      <div class="col-md-4">
        <div class="sheet-title">
          <h2>Other Traits</h2>
        </div>
        <div>
          <!-- testo libero -->
        </div>
      </div>

      <div class="col-md-4">
        <div class="row">
          <div class="sheet-title">
            <h2>Road</h2>
          </div>
          <div>
            <app-attribute [spaced]="true" [maxPoints]="10" [points]="characterSheet.Humanity_Path.value"></app-attribute>
          </div>
        </div>
      </div>

    </div>
  </div>
}


<!-- Modale specializz. -->
<ng-template #content let-modal>
	<div class="modal-body">
    <div><h4>Specializations:</h4></div>
    {{modalContent}}
	</div>
</ng-template>

import { Component, TemplateRef, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterService } from '../../services/master.service';
import { PlayerService } from '../../services/player.service';
import { Campaign } from '../../models/master';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { FormsModule, NgForm } from '@angular/forms';
import { User } from '../../models/user';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-pg-campain',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './pg-campain.component.html',
  styleUrl: './pg-campain.component.scss'
})
export class PgCampainComponent {
  constructor(private route: ActivatedRoute, private router: Router, private master: MasterService, private player: PlayerService, private auth: AuthService) {}

  campaign_id: string | null = this.route.snapshot.paramMap.get("id");
  campagna: Campaign | null = null;
  currentUser: User | null = null;


  expVal: number = 0

	private modalService = inject(NgbModal);


  ngOnInit() {
    this.loadCampaign();
    this.auth.getUserInfo().subscribe({
      next: (user) => {
        this.currentUser = user
      }
    })
  }

  getEnv() {
    return environment
  }

  loadCampaign() {
    this.master.getCampagna(this.campaign_id!).subscribe({
      next: (res) => {
        this.campagna = res
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  countMyPgs(): number {
    let counter = 0;
    this.campagna?.characters.forEach((pg) => {
      if(pg.player.id == this.currentUser?.id) {
        counter++;
      }
    })
    return counter;
  }

  openLg(content: TemplateRef<any>) {
		this.modalService.open(content, { size: 'lg' });
	}

  aggiornaExp(form: NgForm) {
    let selected: number[] = []
    this.campagna?.characters.forEach((pg)=>{
      console.log(form.form.get(pg.id.toString())?.value)
      if (form.form.get(pg.id.toString())?.value == true) {
        selected.push(pg.id);
      }
    })

    if (this.countMyPgs() == 1) {
      this.campagna?.characters.forEach((pg) => {
        if (pg.player!.id == this.currentUser!.id) {
          selected = [pg.id]
        }
      })

    }

    if (selected.length > 0) {
      this.player.spendiExp(selected, this.expVal).subscribe({
        next: (res) => {
          console.log(res)
          this.loadCampaign()

        },
        error: (err) => {
          console.error(err)
          alert(err)
        }
      })
    }
    this.modalService.dismissAll();
  }

}

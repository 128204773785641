import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Campaign } from '../models/master';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private http: HttpClient) { }

  getCampagne() {
    return this.http.get<Campaign[]>(environment.baseUrl + "/master/mycampaigns");
  }

  getCampagna(campagnaId: string) {
    return this.http.get<Campaign>(environment.baseUrl + "/master/campaign/" + campagnaId)
  }
}

<div class="login-container">

  <div class="card" style="width: 18rem;">
    <div class="card-body">
     <div style="text-align: center;"><h3>Login</h3></div>
     <div class="row">
      <div class="col">
        <div class="login-label"> Username</div>
        <div> <input type="text" [(ngModel)]="username" class="form-control"> </div>
      </div>
     </div>
     <div class="row">
      <div class="col">
        <div class="login-label"> Password</div>
        <div> <input type="password"[(ngModel)]="password" class="form-control"> </div>
      </div>
     </div>
     <div style="text-align: center;">
      <button class="btn btn-primary" (click)="doLogin()">Accedi</button>
     </div>
    </div>
  </div>

</div>

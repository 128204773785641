import { Component } from '@angular/core';
import { Campaign } from '../../models/master';
import { MasterService } from '../../services/master.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

environment
@Component({
  selector: 'app-master-home',
  standalone: true,
  imports: [],
  templateUrl: './master-home.component.html',
  styleUrl: './master-home.component.scss'
})
export class MasterHomeComponent {

  constructor(private master: MasterService, private router: Router) {}

  campagne: Campaign[] = []

  ngOnInit() {
    this.master.getCampagne().subscribe({
      next: (res) => {
        this.campagne = res
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  getEnv() {
    return environment
  }

  goToCampagna(id: number) {
    this.router.navigate(["master", "campaign", id])
  }
}


<div class="attribute-container">
  <span style="display: flex;width: 100%;justify-content: center;">
    @for (dot of dots; track $index) {
      <div
        (click)="toggleDot($index)"
        [class.filled]="dot"
        [class]="spaced ? 'sp-dot' : 'dot'">
      </div>
    }
  </span>
</div>



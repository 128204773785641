import { Component } from '@angular/core';
import { PlayerService } from '../../services/player.service';
import { Router } from '@angular/router';
import { Campaign } from '../../models/master';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user';

@Component({
  selector: 'app-pg-home',
  standalone: true,
  imports: [],
  templateUrl: './pg-home.component.html',
  styleUrl: './pg-home.component.scss'
})
export class PgHomeComponent {
  constructor(private auth: AuthService,private player: PlayerService, private router: Router) {}

  campagne: Campaign[] = []
  currentUser: User | null = null

  ngOnInit() {
    this.player.getCampagne().subscribe({
      next: (res) => {
        this.campagne = res
      },
      error: (err) => {
        console.error(err)
      }
    })
    this.auth.getUserInfo().subscribe({
      next: (user) => this.currentUser = user
    })
  }

  getEnv() {
    return environment
  }

  goToCampagna(id: number) {
    this.router.navigate(["player", "campaign", id])
  }
}

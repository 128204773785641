<div class="container" style="margin-top: 10px;">
  <div class="row">

    @for (camp of campagne; track $index) {
      <div class="col-md-4">
        <div class="card" style="width: 100%;" (click)="goToCampagna(camp.id)">
          <img src="{{camp.image ? getEnv().baseUrl + camp.image : 'https://picsum.photos/id/237/200/300'}}" class="card-img-top" alt="...">
          <div class="card-body">
            <div class="card-title" (click)="goToCampagna(camp.id)">{{camp.name}}</div>
            <div class="player-list-title">Giocatori</div>
            <div class="card-text">
              @for (pg of camp.characters; track $index) {
                <div>
                  {{pg.player.first_name}} {{pg.player.last_name}} ({{pg.name}})
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    }

  </div>
</div>


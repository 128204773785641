import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponse } from '../models/authResponse';
import { jwtDecode } from "jwt-decode";
import { User } from '../models/user';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: User | null = null;

  constructor(private http: HttpClient) { }

  login(username: string, password: string) {
    return this.http.post<AuthResponse>(environment.baseUrl + "/api/token/",{
      username: username,
      password: password
    });
  }

  refreshToken(refreshToken: string) {
    return this.http.post<AuthResponse>(environment.baseUrl + "/api/token/refresh/",{
      token: refreshToken
    });
  }

  getUserInfo(): Observable<any>{
    if (!this.currentUser) {
      return this.http.get<User>(environment.baseUrl + "/info/current-user");
    }
    return of(this.currentUser);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwtDecode(token);
    } catch(Error) {
      return null;
    }
  }
}

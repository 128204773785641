@if (campagna != null) {
  <div class="container">
    <div class="">
      <div class="col-md-12">

        <div class="card" style="width: 100%; align-items: center;">
          <img src="{{campagna.image ? getEnv().baseUrl + campagna.image : 'https://picsum.photos/id/237/200/300'}}" class="card-img-top" alt="...">
          <div class="campaign-title">
            <div><h2>{{campagna.name}}</h2></div>
            <div><h5>{{campagna.master.first_name}} {{campagna.master.last_name}}</h5></div>
          </div>
          <div class="card-body">

            <div class="campaign-notes">
              <div class="notes-title"><h3>Note</h3></div>
              <div class="notes-body">{{campagna.notes}}</div>
            </div>

            <div class="pg-area">
              <div class="notes-title"><h3>Personaggi</h3></div>
              <div style="text-align: center;"><button class="btn btn-outline-primary mb-2 me-2" (click)="openLg(longContent)">Assegna Punti Esperienza</button></div>
              <div class="container">
                <div class="row">
                  @for (pg of campagna.characters; track $index) {
                    <div class="col-xl-3 col-md-4">
                      <div class="card">
                        <img src="{{pg.image ? getEnv().baseUrl + pg.image : 'https://picsum.photos/id/237/200/300'}}" class="pg-img-top" alt="...">
                        <div class="card-title">{{pg.name}}</div>
                        <div class="card-subtitle">{{pg.player.first_name}} {{pg.player.last_name}}</div>
                        <div class="card-body exp-area">
                          <div><h5>Esperienza</h5></div>
                          <div class="row">
                            <div class="exp-container">{{pg.exp}}</div>
                          </div>
                          <!--div class="row" style="align-items: center;">
                            <div class="col-sm-6 col-6 exp-container add">Aggiungi</div>
                            <div class="col-sm-6 col-6 exp-container remove">Rimuovi</div>
                          </div-->
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>


          </div>
        </div>

      </div>
    </div>
  </div>


  <ng-template #longContent let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Punti Esperienza</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="container" style="text-align: center;">
      <label for="expInput" class="form-label">Esperienza</label>
      <input type="number" class="form-control" id="expInput" [(ngModel)] = "expVal">
    </div>

    <form #myForm="ngForm" (submit)="aggiornaExp(myForm)">
      <div class="modal-body">
        @for (pg of campagna.characters; track $index) {
          <span style="margin: 2px;">
            <input type="checkbox" class="btn-check" id="{{pg.id}}" name="{{pg.id}}" autocomplete="off" ngModel>
            <label class="btn btn-outline-primary" for="{{pg.id}}"> {{pg.name}} ({{pg.player.first_name}} {{pg.player.last_name}}) </label>
          </span>
        }
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary" >Assegna</button>
        <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
      </div>
    </form>
  </ng-template>



}


import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, QueryList, TemplateRef, ViewChildren, inject } from '@angular/core';
import { Character } from "./../../models/character";
import { Background, CharacterSheet, Discipline } from '../../models/characterSheet';
import { NgFor } from '@angular/common';
import { AttributeComponent } from '../attribute/attribute.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-character-sheet',
  standalone: true,
  imports: [HttpClientModule, AttributeComponent,NgFor],
  templateUrl: './character-sheet.component.html',
  styleUrl: './character-sheet.component.scss'
})
export class CharacterSheetComponent implements AfterViewInit {

  constructor(private http: HttpClient) {}

  private modalService = inject(NgbModal);

  attributesAreas: string[] = ["Physical", "Social", "Mental"]
  abilitiesAreas: string[] = ["Talents", "Skills", "Knowledges"]

  character: Character | undefined;
  characterSheet: CharacterSheet | undefined;

  attributeName: string = "";

  modalContent: string = ""

  @ViewChildren('attribute_name') myClassElements!: QueryList<ElementRef>;

  ngAfterViewInit(): void {
    this.fixWidth()
  }

  ngOnInit() {
    console.log("INIT")
    const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzEyODY0Nzk2LCJpYXQiOjE3MTI3NzgzOTYsImp0aSI6ImU0YWNhYWNiZGQ5MTQ5YWViYmM1ODZiZTA5OWYxNTdmIiwidXNlcl9pZCI6MX0.qZ9P87UQ2vj4iBsEPIzGvib9pW-aY0q58GmALaIXwtY";
    const headers = new HttpHeaders()//.set("Authorization", "Bearer " + token);

    this.http.get<Character>(environment.baseUrl + "/master/character/1", {headers}).subscribe({
      next: res => {
        this.character = res;
        this.characterSheet = this.character.sheet;
        console.log(this.character)
        setTimeout(() => this.fixWidth(), 100)
      },
      error: err => {
        console.log(err)
      }
    })
  }

  fixWidth() {
    let maxWidth = 0;
    this.myClassElements.forEach((element: ElementRef) => {
      maxWidth = Math.max(maxWidth, element.nativeElement.offsetWidth);
    });
    this.attributeName = "width: " + maxWidth + "px";
  }

  openModal(content: TemplateRef<any>, modalContent: string) {
    this.modalContent = modalContent;
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
	}

  getKeys(obj: any) : string[] {
    return Object.keys(obj)
  }

  getObj(obj: any, key: string) {
    return obj[key]
  }

  attrPadding(adv: Discipline[] | Background[]): Discipline[] | Background[] {
    for(let i = 0; i < 5 - adv.length; i++) {
      adv.push({
        name: '______________',
        description: "",
        value: 0
      })
    }
    return adv
  }

}

import { Component, TemplateRef, inject } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Campaign } from '../../models/master';
import { MasterService } from '../../services/master.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, NgForm } from '@angular/forms';
import { PlayerService } from '../../services/player.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-master-campaign',
  standalone: true,
  imports: [FormsModule],
  templateUrl: './master-campaign.component.html',
  styleUrl: './master-campaign.component.scss'
})
export class MasterCampaignComponent {
  constructor(private route: ActivatedRoute, private router: Router, private master: MasterService, private player: PlayerService) {}

  campaign_id: string | null = this.route.snapshot.paramMap.get("id");
  campagna: Campaign | null = null;

  expVal: number = 0

	private modalService = inject(NgbModal);


  ngOnInit() {
    this.loadCampaign();
  }

  getEnv() {
    return environment
  }

  loadCampaign() {
    this.master.getCampagna(this.campaign_id!).subscribe({
      next: (res) => {
        this.campagna = res
      },
      error: (err) => {
        console.error(err)
      }
    })
  }

  openLg(content: TemplateRef<any>) {
		this.modalService.open(content, { size: 'lg' });
	}

  aggiornaExp(form: NgForm) {
    let selected: number[] = []
    this.campagna?.characters.forEach((pg)=>{
      console.log(form.form.get(pg.id.toString())?.value)
      if (form.form.get(pg.id.toString())?.value == true) {
        selected.push(pg.id);
      }
    })

    if (selected.length > 0) {
      this.player.assegnaExp(selected, this.expVal).subscribe({
        next: (res) => {
          console.log(res)
          this.loadCampaign()

        },
        error: (err) => {
          console.error(err)
          alert(err)
        }
      })
    }
    this.modalService.dismissAll();
  }

}

import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {

  const token = localStorage.getItem("access");
  if (token) {
    req = req.clone({
      setHeaders: { Authorization: `Bearer ${token}` }
  });
  }

  return next(req).pipe(catchError(error => {
    if (error instanceof HttpErrorResponse && !req.url.includes('api/token') && (error.status === 401 || error.status === 403)) {
      localStorage.clear();
      location.href="";
    }
    return throwError(() => error);
  }));


};

<ng-template #longContent let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{selectedSubDiscipline.name + " " + selectedLevel.level.toString() + ": " + selectedLevel.name}}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
    <h5>Descrizione:</h5>
    <div class="level-description" [innerHTML]="selectedLevel.description"></div>
    <h5>Sistema:</h5>
    <div class="level-system" [innerHTML]="selectedLevel.system"></div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Chiudi</button>
	</div>
</ng-template>

<div class="card" style="width: 100%;">
  <div class="card-body">
    <h5 class="card-title">{{discipline.name}}</h5>
    <div class="card-text fixed-height" [innerHTML]="discipline.description"></div>
    <div class="card-text">
      <div ngbAccordion class="sub-discipline-accordion" >
        @for(disc of getAllDisciplines(); track $index){
            <div ngbAccordionItem >
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>{{disc.name}}</button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  @for(level of disc.levels; track $index){
                    <div class="level-container" (click)="visualizzaLivello(longContent, level, disc)">
                      <div class="dot-container">
                        @if(!level.isRitual) {
                          @for(dot of [].constructor(level.level); track $index){
                            <span class="discipline-dot"></span>
                          }
                        }
                        @else {
                          <h5>Rituale LV {{romanize(level.level)}}</h5>
                        }
                      </div>
                      <h6>{{level.name}}</h6>
                    </div>
                  }
                </ng-template>
              </div>
            </div>
          </div>
        }
      </div>

    </div>
  </div>
</div>


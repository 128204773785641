export class DisciplineLevel {
  id: number;
  level: number;
  name: string;
  description: string;
  system: string;
  isRitual: boolean;

  constructor(id: number, level: number, name: string, description: string, system: string, isRitual: boolean) {
      this.id = id;
      this.level = level;
      this.name = name;
      this.description = description;
      this.system = system;
      this.isRitual = isRitual;
  }
}

export class Discipline {
  id: number;
  name: string;
  description: string;
  children: Discipline[];
  levels: DisciplineLevel[];

  constructor(id: number, name: string, description: string, children: Discipline[], levels: DisciplineLevel[]) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.children = children;
      this.levels = levels;
  }
}
